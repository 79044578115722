import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { useHistory } from 'react-router-dom'
import Question from '../components/Question'
import Carousel, { consts } from 'react-elastic-carousel'
import { BrowserView, MobileView } from 'react-device-detect'
import renderHTML from 'react-render-html'
import { AppContext } from '../components/Provider'
import Loader from '../components/Loader'

const itemsToShow = 3
const getMid = () => Math.ceil(itemsToShow / 2) - 2

const MySurvey = () => {
  let history = useHistory()

  const { getUserSurveys, user } = React.useContext(AppContext)
  const [commentedSurveys, setCommentedSurveys] = React.useState([])
  React.useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken')
    if (!jwtToken) history.push({ pathname: `/` })
    getSurverys()
  }, [])

  const getSurverys = async () => {
    const data = await getUserSurveys()
    setCommentedSurveys(data)
    setLoading(false)
  }
  const [midItemIndex, setMidItemIndex] = React.useState(getMid)
  const [loading, setLoading] = React.useState(true)

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 550, itemsToShow: 1, pagination: false },
    { width: 850, itemsToShow: 3, pagination: false },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 1450, itemsToShow: 3, pagination: false },
    { width: 1750, itemsToShow: 3, pagination: false },
  ]

  const onChange = (_, next) => {
    const mid = getMid()
    setMidItemIndex(mid + next.index)
  }

  const test = (idx) => {
    var element = document.getElementById(`flipCard${idx}`)
    element.classList.toggle('hover')
  }

  const arrowComponent = ({ type, onClick, isEdge }) => {
    const icon =
      type === consts.PREV
        ? 'fa fa-angle-double-left carouselIconLeft'
        : 'fa fa-angle-double-right carouselIconRight'
    return (
      <div className="carouselButtonWrapper">
        <div
          className="carouselButtonContainer"
          role="button"
          onClick={onClick}
        >
          <i className={icon} aria-hidden="true"></i>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img
            style={{ opacity: 0.4, zIndex: -1 }}
            className="keshis"
            width="900px"
            src={keshis}
          />
        </div>
      </div>
      <div className="container">
        {/*NO PACKAGE <div className="styling-example d-none d-lg-block d-md-none questionWrapper"> */}
        <BrowserView>
          <div style={{ marginBottom: 20 }} className="questionWrapper">
            <Carousel
              itemsToShow={itemsToShow}
              enableMouseSwipe={false}
              showEmptySlots
              breakPoints={breakPoints}
              renderArrow={arrowComponent}
              autoTabIndexVisibleItems={false}
              onNextStart={onChange}
              onPrevStart={onChange}
            >
              <div />
              {loading ||
                (commentedSurveys?.length < 1 && (
                  <Question
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div>
                      <h1 className="surveyQuestion text-center">
                        Cevaplanmış Anket Bulunmamaktadır!
                      </h1>
                    </div>
                  </Question>
                ))}
              {loading && (
                <Question
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Loader />
                  </div>
                </Question>
              )}
              {commentedSurveys?.map((item, idx) => (
                <div
                  class="flip-container"
                  id={`flipCard${idx}`}
                  onClick={() => (midItemIndex === idx ? test(idx) : null)}
                >
                  <div class="flipper ">
                    <div class="front">
                      <Question
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          opacity: midItemIndex === idx ? 1 : 0.5,
                          transition:
                            midItemIndex === idx
                              ? 'transform 700ms ease'
                              : 'transform 300ms ease',
                          transform:
                            midItemIndex === idx ? 'scale(1)' : 'scale(.7)',
                        }}
                        key={item}
                      >
                        <div>
                          <h1 className="surveyQuestion">{item.title}</h1>
                        </div>
                        <div className="cardNumberContainer">
                          <h1 className="questionNumber">{idx + 1}</h1>
                        </div>
                      </Question>
                    </div>
                    <div class="back">
                      <Question
                        style={{
                          opacity: midItemIndex === idx ? 1 : 0.5,
                          transition:
                            midItemIndex === idx
                              ? 'transform 700ms ease'
                              : 'transform 300ms ease',
                          transform:
                            midItemIndex === idx ? 'scale(1)' : 'scale(.7)',
                        }}
                        key={item}
                      >
                        <div>
                          <h1
                            className="surveyQuestion overflow-auto"
                            style={{ height: 330 }}
                          >
                            {renderHTML(item.comment)}
                          </h1>
                        </div>
                      </Question>
                    </div>
                  </div>
                </div>
              ))}
              <div />
            </Carousel>
          </div>
        </BrowserView>

        {/* NO PACKAGE <div className="d-lg-none d-xs-block"> */}
        <MobileView>
          {/* {items.map((item, idx) => (
            <div className="col-lg-4 cardBg">
              <Question
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                key={item}
                onClick={() => [setSelected(item), setModal(true)]}
              >
                {' '}
                <div>
                  <h1 className="surveyQuestion">{item.title}</h1>
                </div>
                <div className="cardNumberContainer">
                  <h1 className="questionNumber">{idx + 1}</h1>
                </div>
              </Question>
            </div>
          ))} */}
          {commentedSurveys?.map((item, idx) => (
            <div className="d-flex align-items-center justify-content-center">
              <div
                class="flip-container cardBg"
                id={`flipCard${idx}`}
                onClick={() => test(idx)}
              >
                <div class="flipper">
                  <div class="front">
                    <Question
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      key={item}
                    >
                      <div>
                        <h1 className="surveyQuestion">{item.title}</h1>
                      </div>
                      <div className="cardNumberContainer">
                        <h1 className="questionNumber">{idx + 1}</h1>
                      </div>
                    </Question>
                  </div>
                  <div class="back">
                    <Question key={item}>
                      <div>
                        <h1
                          style={{ height: 330 }}
                          className="surveyQuestion overflow-auto"
                        >
                          {renderHTML(item.comment)}
                        </h1>
                      </div>
                    </Question>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </MobileView>
      </div>
    </div>
  )
}

export default MySurvey
