import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../components/Provider'

const Homepage = () => {
  const [login, setLogin] = React.useState(
    localStorage.getItem('jwtToken') ? true : false
  )
  const [questionLengt, setQuestionLength] = React.useState(0)
  let history = useHistory()
  const { getQuestions } = React.useContext(AppContext)
  const getSurveyQuestionLengt = async () => {
    if (!login) return
    const data = await getQuestions()
    setQuestionLength(data?.length)
  }
  React.useEffect(() => {
    getSurveyQuestionLengt()
  }, [])

  if (login) {
    return (
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo" style={{ flexDirection: 'column' }}>
            <img className="keshis" width="900px" src={keshis} />
          </div>
        </div>

        <div style={{ marginBottom: 20 }} className="container">
          <div className="row">
            <div
              onClick={() => history.push({ pathname: `anketbaslat` })}
              className="col-lg-4 cardBg cardBgLogined"
            >
              <div className="inCard">
                <div className="cardContentLogined">
                  <div className="d-flex align-items-center">
                    <a>ANKET BAŞLAT</a>
                  </div>
                  <h2>
                    Aradığın hediyeyi bulmamız için sana {questionLengt} soruluk
                    bir anket hazırladık
                  </h2>
                </div>
              </div>
            </div>
            <div
              onClick={() => history.push({ pathname: `anketlerim` })}
              className="col-lg-4 cardBg cardBgLogined"
            >
              <div className="inCard">
                <div className="cardContentLogined">
                  <div className="d-flex align-items-center">
                    <a>ANKETLERİM</a>
                  </div>
                  <h2>Anketlerini buradan takip edebilirsin.</h2>
                </div>
              </div>
            </div>
            <div
              onClick={() => history.push({ pathname: `profil` })}
              className="col-lg-4 cardBg cardBgLogined"
            >
              <div className="inCard">
                <div className="cardContentLogined">
                  <div className="d-flex align-items-center">
                    <a>PROFİLİM</a>
                  </div>
                  <h2>Profil ayarlarını burdan görüntüleyebilirsin.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className="container">
        <div className="row">
          <div className="col-lg-4 cardBg">
            <div className="inCard">
              <div className="cardContent">
                <div className="d-flex align-items-center">
                  <a href="/kayitol">KAYIT OL </a> &nbsp; <span>veya</span>{' '}
                  &nbsp; <a href="/girisyap">GİRİŞ YAP </a>
                </div>
                <h2>
                  Hoşgeldin! Buraya gelerek ilk adımı gerçekleştirdin. Artık
                  kayıt olabilir veya kayıtlıysan giriş yapabilirsin.
                </h2>
              </div>

              <div className="cardNumberContainer">
                <h1 className="cardNumber">1</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4 cardBg">
            <div className="inCard">
              <div className="cardContent">
                <h1>SORULARI CEVAPLA</h1>
                <h2>
                  Kendin veya bir başkası için soruları cevaplayarak hediyelere
                  ulaşabilirsin.
                </h2>
              </div>
              <div className="cardNumberContainer">
                <h1 className="cardNumber">2</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4 cardBg">
            <div className="inCard">
              <div className="cardContent">
                <h1>Aradığın Hediye Bulunsun!</h1>
                {/*                 <h2>Lorem ipsum</h2>
                 */}
              </div>
              <div className="cardNumberContainer">
                <h1 className="cardNumber">3</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homepage
