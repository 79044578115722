import './App.css'
import './index.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Homepage from './pages/Homepage'
import Application from './pages/Application'
import Contact from './pages/Contact'
import SSS from './pages/SSS'
import Register from './pages/Register'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Profile from './pages/Profile'
import StartSurvey from './pages/StartSurvey'
import Survey from './pages/Survey'
import MySurvey from './pages/MySurvey'
import NotFound from './pages/NotFound'
import { AppProvider } from './components/Provider'
function App() {
  return (
    <AppProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/uygulama" component={Application} />
          <Route path="/iletisim" component={Contact} />
          <Route path="/sss" component={SSS} />
          <Route path="/kayitol" component={Register} />
          <Route path="/girisyap" component={Login} />
          <Route path="/sifremiunuttum" component={ForgotPassword} />
          <Route path="/profil" component={Profile} />
          <Route path="/anketbaslat" component={StartSurvey} />
          <Route path="/anket" component={Survey} />
          <Route path="/anketlerim" component={MySurvey} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </AppProvider>
  )
}

export default App
