import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { AppContext } from '../components/Provider'
import SweetAlert from 'react-bootstrap-sweetalert'
import jwtDecode from 'jwt-decode'
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const { createContactMessage, user } = React.useContext(AppContext)
  const [name, setName] = React.useState('')
  const nameInput = React.createRef()
  const [mail, setMail] = React.useState('')
  const mailInput = React.createRef()
  const [message, setMessage] = React.useState('')
  const messageInput = React.createRef()
  const [successShow, setSuccessShow] = React.useState(false)
  const [warningShow, setWarningShow] = React.useState(false)
  const [warningMessage, setWarningMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')

  React.useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken')

    if (jwtToken) {
      const userInfo = jwtDecode(jwtToken)
      setName(userInfo?.name)
      setMail(userInfo?.mail)
    }
  }, [])

  const recaptchaRef = React.useRef();


  const sumbit = async () => {
    const token = await recaptchaRef.current.executeAsync();
    if (!name || !mail || !message) {
      setWarningMessage('Lütfen tüm alanları doldurun.')
      setWarningShow(!warningShow)
      return
    }

    const response = await createContactMessage({ name, mail, message })

    if (response.success) {
      setSuccessMessage('Mesajınız başarıyla iletildi.')
      setSuccessShow(!successShow)
      setTimeout(() => {
        setMessage('')
        setSuccessShow(false)
      }, 2000)
    } else {
      setWarningMessage(response.error.message)
      setWarningShow(!warningShow)
    }
  }

  const enterKey = (e) => {
    console.log(e)
    if (e.keyCode === 13) {
      switch (e.target.id) {
        case '1':
          mailInput.current.focus()
          break
        case '2':
          messageInput.current.focus()
          break

        default:
          break
      }
    }
  }
  return (
    <div>
      <SweetAlert
        success
        title={<h1 className="alertSuccessTitle">Başarılı</h1>}
        show={successShow}
        showConfirm={false}
      >
        <h1 className="alertContent">{successMessage}</h1>
      </SweetAlert>

      <SweetAlert
        error
        onConfirm={() => setWarningShow(!warningShow)}
        title={<h1 className="alertErrorTitle">Başarısız</h1>}
        show={warningShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="danger"
      >
        <h1 className="alertContent">{warningMessage}</h1>
      </SweetAlert>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className="container">
        <div className="">
          <div className="inCardNoHeight d-flex align-items-center justify-content-center pb-5 pt-5">
            <div className="row col-lg-12">
              <div className="col-lg-6">
                <div className="inputContainer">
                  <i
                    className="fa fa-user"
                    style={{ color: 'white', width: 30 }}
                  />
                  <input
                    placeholder="Ad"
                    className="input"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => enterKey(e)}
                    ref={nameInput}
                    id="1"
                  />
                </div>

                <div className="inputContainer">
                  <i
                    className="fa fa-envelope"
                    style={{ color: 'white', width: 30 }}
                  />
                  <input
                    placeholder="E-Posta"
                    className="input"
                    type="text"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    onKeyDown={(e) => enterKey(e)}
                    ref={mailInput}
                    id="2"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="inputMultilineContainer">
                  <textarea
                    placeholder="Mesaj"
                    rows="5"
                    className="inputMultiline"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => enterKey(e)}
                    ref={messageInput}
                    id="3"
                  />
                </div>
              </div>
              <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LdDxbsaAAAAAK5AtfDbub6e7NpgNM0ljlfpLBVy"
              />
              <div
                onClick={() => sumbit()}
                className="buttonContainerFull mt-2"
              >
                <h1>GÖNDER</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
