import React, { Component } from 'react'

/**
 * Renders the preloader
 */
class Loader extends Component {
  render() {
    return (
      <div className="preloader d-flex align-items-center justify-content-center">
        <div className="status">
          <div
            className="spinner-border avatar-sm m-2"
            role="status"
            style={{ color: 'white' }}
          ></div>
        </div>
      </div>
    )
  }
}

export default Loader
