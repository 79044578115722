import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { AppContext } from '../components/Provider'
import SweetAlert from 'react-bootstrap-sweetalert'

const Application = () => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className="container">
        <div className="">
          <div className="inCardNoHeight d-flex align-items-center justify-content-center pb-5 pt-5">
            <div className="d-flex">
              <h3 className="navbar-logo">Y A K I N D A</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Application
