import styled from 'styled-components'

export default styled.div`
  display: flex;
  padding: 20px 30px 60px 30px;

  height: 375px;
  width: 100%;
  background: rgb(242, 242, 242, 0.14);
  border-radius: 30px;
`
