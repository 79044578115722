import React from 'react'
import '../App.css'
import Faq from 'react-faq-component'
import keshis from '../keshis.png'
import { AppContext } from '../components/Provider'
import Loader from '../components/Loader'

const SSS = () => {
  const { getSSS } = React.useContext(AppContext)
  const [sss, setSSS] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    getFaqs()
  }, [])

  const getFaqs = async () => {
    const data = await getSSS()
    const modifyData = {
      rows: data.map(({ title, description, _id }) => ({
        _id,
        title,
        content: description,
      })),
    }
    setSSS(modifyData)
    setLoading(false)
  }

  const styles = {
    bgColor: 'transparent',
    titleTextColor: '#d8d8d8',
    rowTitleColor: '#fbf4ef',
    rowContentColor: 'grey',
  }

  return (
    <div className="bg-1">
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div className="container">
        <div className="cardBg">
          <div className="inCardSSS p-5">
            {loading && <Loader />}
            <Faq styles={styles} data={sss} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SSS
