import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

const Navbar = () => {
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  return (
    <nav className="navbar">
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      <div className="menu-icon2">
        <a href="/">K E S H I S</a>
      </div>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            ANASAYFA
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/uygulama" className="nav-links" onClick={closeMobileMenu}>
            UYGULAMA
          </Link>
        </li>
        <Link
          to="/"
          className="navbar-logo"
          style={{}}
          onClick={closeMobileMenu}
        >
          K E S H I S{' '}
        </Link>
        <li className="nav-item">
          <Link to="/iletisim" className="nav-links" onClick={closeMobileMenu}>
            İLETİŞİM
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/sss" className="nav-links" onClick={closeMobileMenu}>
            S.S.S.
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
