import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { useHistory } from 'react-router-dom'
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'
import { AppContext } from '../components/Provider'
import SweetAlert from 'react-bootstrap-sweetalert'
import { OldSocialLogin as SocialLogin } from 'react-social-login'

const Login = () => {
  const [mail, setMail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const passwordInput = React.createRef()
  const [check, setCheck] = React.useState(false)
  const [successShow, setSuccessShow] = React.useState(false)
  const [warningShow, setWarningShow] = React.useState(false)
  const [warningMessage, setWarningMessage] = React.useState('')
  let history = useHistory()
  const { login,socialLogin } = React.useContext(AppContext)

  const sumbit = async () => {
    if (!mail || !password) {
      setWarningMessage('Lütfen tüm alanları doldurun.')
      setWarningShow(!warningShow)
      return
    }

    const response = await login({ mail, password })
    if (response.success) {
      setSuccessShow(!successShow)
      setTimeout(() => {
        history.push('/')
      }, 1500)
    } else {
      setWarningMessage(response.info.errorMessage)
      setWarningShow(!warningShow)
    }
  }

  const responseFacebook = (response) => {
    console.log('response:', response)
  }
  const componentClicked = (data) => {
    console.log('data:', data)
  }

  const handleSocialLogin = async (user, err) => {
    let name, socialToken
    if(user._provider === 'google') {
      name = 'Google';
      socialToken = user._token.idToken
    } else if (user._provider === 'facebook') {
      name = 'Facebook';
      socialToken = user._token.accessToken
    }
    if(!name || !socialToken) {
      setWarningMessage("Geçersiz işlem!")
      setWarningShow(!warningShow)
    }

    const response = await socialLogin({ name, socialToken })
    if (response.success) {
      setSuccessShow(!successShow)
      setTimeout(() => {
        history.push('/')
      }, 1500)
    } else {
      setWarningMessage(response.info.errorMessage)
      setWarningShow(!warningShow)
    }
  }

  const enterKey = (e) => {
    console.log(e)
    if (e.keyCode === 13) {
      switch (e.target.id) {
        case '1':
          passwordInput.current.focus()
          break
        case '2':
          sumbit()
          break

        default:
          break
      }
    }
  }

  return (
    <div>
      <SweetAlert
        success
        title={<h1 className="alertSuccessTitle">Giriş Başarılı</h1>}
        show={successShow}
        showConfirm={false}
      >
        <h1 className="alertContent">Anasayfaya yönlendiriliyorsunuz...</h1>
      </SweetAlert>

      <SweetAlert
        error
        onConfirm={() => setWarningShow(!warningShow)}
        title={<h1 className="alertErrorTitle">Giriş Başarısız</h1>}
        show={warningShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="danger"
      >
        <h1 className="alertContent">{warningMessage}</h1>
      </SweetAlert>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo bg" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className="container col-lg-4 ">
        <div className="registerContainer">
          <div className="inputContainer mt-3">
            <i
              className="fa fa-envelope"
              style={{ color: 'white', width: 30 }}
            />
            <input
              placeholder="E-Posta"
              className="input"
              type="text"
              id="1"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              onKeyDown={(e) => enterKey(e)}
            />
          </div>

          <div className="inputContainer">
            <i className="fa fa-key" style={{ color: 'white', width: 30 }} />
            <input
              placeholder="Şifre"
              className="input"
              type="password"
              id="2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => enterKey(e)}
              ref={passwordInput}
            />
          </div>

          <div className="contract mt-2 row justify-content-between ml-1 mr-1">
            <div
              onClick={() => setCheck(!check)}
              className="d-flex align-items-center"
            >
              <div
                className={check ? 'checkBoxChecked mr-2' : 'checkBox mr-2'}
              />
              Beni Hatırla
            </div>
            <div onClick={() => history.push({ pathname: `sifremiunuttum` })}>
              Şifreni mi unuttun? Giriş kodu al.
            </div>
          </div>
          <div className="socialButton">
            <div className="col-lg-6 col-sm-12 m-0 p-0">
              <SocialLogin
                provider="facebook"
                appId="199806088554368"
                callback={handleSocialLogin}
              >
                <FacebookLoginButton>
                  <h1 className="socialText1">Facebook ile giriş</h1>
                </FacebookLoginButton>
              </SocialLogin>
            </div>
            <div className="col-lg-6 col-sm-12 m-0 p-0">
              <SocialLogin
                provider="google"
                appId="775188280714-t6lhlobfh6c6kr5oa29un56tes12pp6t.apps.googleusercontent.com"
                callback={handleSocialLogin}
              >
                <GoogleLoginButton>
                  <h1 className="socialText2">Google ile giriş</h1>
                </GoogleLoginButton>
              </SocialLogin>
            </div>
          </div>

          <div onClick={() => sumbit()} className="buttonContainer mt-2 ">
            <h1>Giriş Yap</h1>
          </div>

          <div className="contract mt-2 ">
            <a
              href="/kayitol"
              className="d-flex align-items-center justify-content-center"
            >
              Hesabın yok mu? Kayıt ol!
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
