import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

const AppContext = React.createContext()

const AppProvider = (props) => {
  const [user, setUser] = useState(null)
  const API = `https://keshisapi.herokuapp.com`

  const createUser = async ({ name, mail, password }) => {
    const { data } = await axios({
      method: 'post',
      url: `${API}/users/`,
      data: {
        name,
        mail,
        password,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    return data
  }

  const createContactMessage = async ({ name, mail, message }) => {
    const { data } = await axios({
      method: 'post',
      url: `${API}/contact/`,
      data: {
        name,
        mail,
        message,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    return data
  }

  const resetPass = async ({ mail }) => {
    const { data } = await axios({
      method: 'post',
      url: `${API}/users/reset/`,
      data: {
        mail,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    return data
  }

  const createUserAnswer = async ({
    title,
    answeredQuestions,
    description,
  }) => {
    const jwtToken = localStorage.getItem('jwtToken')

    const { data } = await axios({
      method: 'post',
      url: `${API}/answers/`,
      data: {
        title,
        answeredQuestions,
        description,
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtToken,
      },
    })
    return data
  }

  const updateUser = async ({ name, mail, password }) => {
    const jwtToken = localStorage.getItem('jwtToken')
    const { data } = await axios({
      method: 'put',
      url: `${API}/users/`,
      data: {
        name,
        mail,
        password,
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtToken,
      },
    })
    if (data.success) {
      await localStorage.setItem('jwtToken', data.data.token)
      getToken()
    }
    return data
  }

  const login = async ({ mail, password }) => {
    const { data } = await axios({
      method: 'post',
      url: `${API}/users/login`,
      data: {
        mail,
        password,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    if (data.success) {
      await localStorage.setItem('jwtToken', data.data.token)
      getToken()
    }
    return data
  }

  const socialLogin = async ({ name, socialToken }) => {
    const { data } = await axios({
      method: 'post',
      url: `${API}/users/social`,
      data: {
        name,
        token: socialToken,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    if (data.success) {
      await localStorage.setItem('jwtToken', data.data.token)
      getToken()
    }
    return data
  }

  const fetchResetCode = async ({ mail, key }) => {
    const { data } = await axios({
      method: 'post',
      url: `${API}/users/recover`,
      data: {
        mail,
        key,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    if (data.success) {
      await localStorage.setItem('jwtToken', data.data.token)
      getToken()
    }
    return data
  }

  const removeToken = async () => {
    await localStorage.removeItem('jwtToken')
    getToken()
    return
  }

  const getToken = async () => {
    const jwtToken = localStorage.getItem('jwtToken')
    if (jwtToken) {
      const userInfo = jwtDecode(jwtToken)
      setUser(userInfo)
    } else {
      setUser(null)
    }
  }

  useEffect(() => {
    getToken()
  }, [])

  const getSSS = async () => {
    const { data } = await axios({
      method: 'get',
      url: `${API}/faqs/active`,
      headers: { 'Content-Type': 'application/json' },
    })
    return data.data
  }

  const getUserSurveys = async () => {
    const jwtToken = localStorage.getItem('jwtToken')
    const { data } = await axios({
      method: 'get',
      url: `${API}/answers/commented`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtToken,
      },
    })
    return data.data
  }

  const getQuestions = async () => {
    const jwtToken = localStorage.getItem('jwtToken')
    const { data } = await axios({
      method: 'get',
      url: `${API}/questions`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtToken,
      },
    })
    return data.data
  }

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        API,
        getToken,
        createUser,
        login,
        removeToken,
        updateUser,
        createContactMessage,
        getSSS,
        getQuestions,
        createUserAnswer,
        getUserSurveys,
        resetPass,
        fetchResetCode,
        socialLogin
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
