import React from 'react'
import '../App.css'
import keshis from '../keshis.png'

const NotFound = () => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 cardBg">
            <div className="inCard">
              <div className="cardContentLogined">
                <div className="d-flex align-items-center">
                  <a>Böyle bir sayfa bulunmuyor!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
