import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { useHistory } from 'react-router-dom'

const StartSurvey = () => {
  let history = useHistory()

  React.useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken')
    if (!jwtToken) history.push({ pathname: `/` })
  }, [])

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className="container">
        <div className="row">
          <div
            onClick={() => history.push({ pathname: `anket` })}
            className="col-lg-6 cardBg cardBgLogined"
          >
            <div className="inCard">
              <div className="cardContentLogined">
                <div className="d-flex align-items-center">
                  <a>KENDİM İÇİN</a>
                </div>
                <h2>
                  Lorem ipsum dolor sit amet, co tuer adipiscing elit, sed diam
                  n
                </h2>
              </div>
            </div>
          </div>
          <div
            onClick={() => history.push({ pathname: `anket` })}
            className="col-lg-6 cardBg cardBgLogined"
          >
            <div className="inCard">
              <div className="cardContentLogined">
                <div className="d-flex align-items-center">
                  <a>BAŞKASI İÇİN</a>
                </div>
                <h2>
                  Lorem ipsum dolor sit amet, co tuer adipiscing elit, sed diam
                  n
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartSurvey
