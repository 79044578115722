import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { AppContext } from '../components/Provider'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from 'react-router-dom'

const Register = () => {
  const [name, setName] = React.useState('')
  const nameInput = React.createRef()
  const [mail, setMail] = React.useState('')
  const mailInput = React.createRef()
  const [password, setPassword] = React.useState('')
  const passwordInput = React.createRef()
  const [check, setCheck] = React.useState(false)
  const [successShow, setSuccessShow] = React.useState(false)
  const [warningShow, setWarningShow] = React.useState(false)
  const [warningMessage, setWarningMessage] = React.useState('')
  const { createUser } = React.useContext(AppContext)
  let history = useHistory()

  const sumbit = async () => {
    if (!name || !mail || !password) {
      setWarningMessage('Lütfen tüm alanları doldurun.')
      setWarningShow(!warningShow)
      return
    }

    if (!check) {
      setWarningMessage('Lütfen üyelik sözleşmesini kabul edin.')
      setWarningShow(!warningShow)
      return
    }

    const response = await createUser({ name, mail, password })

    if (response.success) {
      setSuccessShow(!successShow)
    } else {
      setWarningMessage(response.error.message)
      setWarningShow(!warningShow)
    }
  }

  const enterKey = (e) => {
    console.log(e)
    if (e.keyCode === 13) {
      switch (e.target.id) {
        case '1':
          mailInput.current.focus()
          break
        case '2':
          passwordInput.current.focus()
          break

        case '3':
          sumbit()
          break

        default:
          break
      }
    }
  }

  return (
    <div>
      <SweetAlert
        success
        title={<h1 className="alertSuccessTitle">Kayıt Başarılı</h1>}
        show={successShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="success"
        onConfirm={() => history.push('/girisyap')}
      >
        <h1 className="alertContent">Artık giriş yapabilirsin...</h1>
      </SweetAlert>

      <SweetAlert
        error
        onConfirm={() => setWarningShow(!warningShow)}
        title={<h1 className="alertErrorTitle">Kayıt Başarısız</h1>}
        show={warningShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="danger"
      >
        <h1 className="alertContent">{warningMessage}</h1>
      </SweetAlert>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo bg" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className="container col-lg-4 ">
        <div className="registerContainer">
          <h1>Lorem ipsum dolor sit amet, consectetu</h1>

          <div className="inputContainer mt-3">
            <i className="fa fa-user" style={{ color: 'white', width: 30 }} />
            <input
              placeholder="Ad"
              className="input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => enterKey(e)}
              ref={nameInput}
              id="1"
            />
          </div>

          <div className="inputContainer">
            <i
              className="fa fa-envelope"
              style={{ color: 'white', width: 30 }}
            />
            <input
              placeholder="E-Posta"
              className="input"
              type="text"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              onKeyDown={(e) => enterKey(e)}
              ref={mailInput}
              id="2"
            />
          </div>

          <div className="inputContainer">
            <i className="fa fa-key" style={{ color: 'white', width: 30 }} />
            <input
              placeholder="Şifre"
              className="input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => enterKey(e)}
              ref={passwordInput}
              id="3"
            />
          </div>

          <div className="contract mt-2 ">
            <div
              onClick={() => setCheck(!check)}
              className="d-flex align-items-center"
            >
              <div
                className={check ? 'checkBoxChecked mr-2' : 'checkBox mr-2'}
              />
              Sözleşmeyi okudum ve kabul ediyorum.
            </div>
          </div>

          <div onClick={() => sumbit()} className="buttonContainer mt-2 ">
            <h1>KAYIT OL</h1>
          </div>

          <div className="contract mt-2 mb-2 ">
            <a
              href="/girisyap"
              className="d-flex align-items-center justify-content-center"
            >
              Zaten kayıtlı mısın? Giriş yap!
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
