import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { AppContext } from '../components/Provider'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import jwtDecode from 'jwt-decode'

const Profile = () => {
  const { removeToken, user, updateUser } = React.useContext(AppContext)
  let history = useHistory()

  React.useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken')

    if (!jwtToken) {
      history.push({ pathname: `/` })
    } else {
      const userInfo = jwtDecode(jwtToken)
      setName(userInfo?.name)
      setMail(userInfo?.mail)
    }
  }, [])

  const [name, setName] = React.useState('')
  const [mail, setMail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [successShow, setSuccessShow] = React.useState(false)
  const [warningShow, setWarningShow] = React.useState(false)
  const [warningMessage, setWarningMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')

  const logout = async () => {
    await removeToken()
    setSuccessMessage('Çıkış yapılıyor...')
    setSuccessShow(!successShow)
    setTimeout(() => {
      history.push('/')
    }, 1500)
  }

  const sumbit = async () => {
    if (!mail || !name) {
      setWarningMessage('İsim ve mail dolu olmak zorunda.')
      setWarningShow(!warningShow)
      return
    }

    const response = await updateUser({ name, mail, password })

    if (response.success) {
      setSuccessMessage('Bilgilerin başarıyla güncellendi.')
      setSuccessShow(!successShow)
      setTimeout(() => {
        setSuccessShow(false)
      }, 2000)
    } else {
      setWarningMessage(response.info.errorMessage)
      setWarningShow(!warningShow)
    }
  }

  return (
    <div>
      <SweetAlert
        success
        title={<h1 className="alertSuccessTitle">Başarılı</h1>}
        show={successShow}
        showConfirm={false}
      >
        <h1 className="alertContent">{successMessage}</h1>
      </SweetAlert>

      <SweetAlert
        error
        onConfirm={() => setWarningShow(!warningShow)}
        title={<h1 className="alertErrorTitle">Başarısız</h1>}
        show={warningShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="danger"
      >
        <h1 className="alertContent">{warningMessage}</h1>
      </SweetAlert>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo bg" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <div className="container col-lg-4 ">
        <div className="registerContainer">
          <div className="inputContainer mt-3">
            <i className="fa fa-user" style={{ color: 'white', width: 30 }} />
            <input
              placeholder="Ad"
              className="input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="inputContainer">
            <i
              className="fa fa-envelope"
              style={{ color: 'white', width: 30 }}
            />
            <input
              placeholder="E-Posta"
              className="input"
              type="text"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </div>

          <div className="inputContainer">
            <i className="fa fa-key" style={{ color: 'white', width: 30 }} />
            <input
              placeholder="Şifre"
              className="input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div onClick={() => sumbit()} className="buttonContainer mt-1">
            <h1>GÜNCELLE</h1>
          </div>
          <div onClick={() => logout()} className="buttonContainer mb-5 ">
            <h1>ÇIKIŞ YAP</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
