import React from 'react'
import '../App.css'
import { AppContext } from '../components/Provider'
import keshis from '../keshis.png'
import ReactCodeInput from 'react-verification-code-input'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'

const ForgotPassword = () => {
  const [mail, setMail] = React.useState('')
  const [key, setKey] = React.useState('')
  const [check, setCheck] = React.useState(false)
  const { resetPass, fetchResetCode } = React.useContext(AppContext)
  const [warningShow, setWarningShow] = React.useState(false)
  const [warningMessage, setWarningMessage] = React.useState('')
  const [successShow, setSuccessShow] = React.useState(false)

  let history = useHistory()

  const sumbit = async () => {
    if (!mail) {
      setWarningMessage('Kayıt olduğunuz mail adresini giriniz.')
      setWarningShow(!warningShow)
      return
    }
    const response = await resetPass({ mail })

    if (response.success) {
      setCheck(true)
    } else {
      setWarningMessage(response.info.errorMessage)
      setWarningShow(!warningShow)
    }
  }

  const loginWithCode = async (val = key) => {
    if (!mail || val.length !== 6) {
      setWarningMessage('Lütfen kodu tam olarak doldurunuz.')
      setWarningShow(!warningShow)
      return
    }
    const response = await fetchResetCode({ mail, key: val })

    if (response.success) {
      setSuccessShow(!successShow)
      setTimeout(() => {
        history.push('/')
      }, 1500)
    } else {
      setWarningMessage(response.info.errorMessage)
      setWarningShow(!warningShow)
    }
  }
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo bg" style={{ flexDirection: 'column' }}>
          <img className="keshis" width="900px" src={keshis} />
        </div>
      </div>

      <SweetAlert
        success
        title={<h1 className="alertSuccessTitle">Başarılı</h1>}
        show={successShow}
        showConfirm={false}
      >
        <h1 className="alertContent">Anasayfaya yönlendiriliyorsunuz...</h1>
      </SweetAlert>

      <SweetAlert
        error
        onConfirm={() => setWarningShow(!warningShow)}
        title={<h1 className="alertErrorTitle">Başarısız</h1>}
        show={warningShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="danger"
      >
        <h1 className="alertContent">{warningMessage}</h1>
      </SweetAlert>

      <div style={{ marginBottom: 20 }} className="container col-lg-4 ">
        {check ? (
          <div className="d-flex justify-content-center p-5">
            <ReactCodeInput
              type="number"
              fields={6}
              onChange={(val) => setKey(val)}
              onComplete={(val) => loginWithCode(val)}
              fieldHeight={90}
            />
          </div>
        ) : (
          <div className="registerContainer">
            <h1>
              Kayıt olmuş olduğun mail adresine gönderilecek 6 haneli kod ile
              giriş yapabilirsin.
            </h1>
            <div className="inputContainer mt-3">
              <i
                className="fa fa-envelope"
                style={{ color: 'white', width: 30 }}
              />
              <input
                placeholder="E-Posta"
                className="input"
                type="text"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                onKeyDown={(e) => (e.keyCode === 13 ? sumbit() : null)}
              />
            </div>

            <div onClick={() => sumbit()} className="buttonContainer mt-2 ">
              <h1>Kod Gönder</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
