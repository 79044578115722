import React from 'react'
import '../App.css'
import keshis from '../keshis.png'
import { useHistory } from 'react-router-dom'
import Question from '../components/Question'
import Carousel, { consts } from 'react-elastic-carousel'
import { isMobile } from 'react-device-detect'
import SweetAlert from 'react-bootstrap-sweetalert'
import { AppContext } from '../components/Provider'

const itemsToShow = 3
const getMid = () => Math.ceil(itemsToShow / 2) - 2

const Survey = () => {
  const { getQuestions, createUserAnswer } = React.useContext(AppContext)
  const [midItemIndex, setMidItemIndex] = React.useState(getMid)
  const [surveyTitle, setSurveyTitle] = React.useState('')
  const [answers, setAnswers] = React.useState([])
  const [message, setMessage] = React.useState('')

  const [showSurvey, setShowSurvey] = React.useState(false)
  const [successShow, setSuccessShow] = React.useState(false)
  const [warningShow, setWarningShow] = React.useState(false)
  const [warningMessage, setWarningMessage] = React.useState('')
  const [questions, setQuestions] = React.useState([])
  let history = useHistory()
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 550, itemsToShow: 1, pagination: false },
    { width: 850, itemsToShow: 3, pagination: false },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 1450, itemsToShow: 3, pagination: false },
    { width: 1750, itemsToShow: 3, pagination: false },
  ]

  React.useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken')
    if (!jwtToken) history.push({ pathname: `/` })
  }, [])

  const sumbit = async () => {
    if (!surveyTitle) {
      setWarningMessage('Lütfen tüm alanları doldurun.')
      setWarningShow(!warningShow)
      return
    }
    const data = await getQuestions()
    setQuestions(data)
    setSuccessShow(!successShow)
    setTimeout(() => {
      setShowSurvey(true)
      setSuccessShow(false)
    }, 1500)
  }

  const complateSurvey = async () => {
    if (!surveyTitle || questions.length > answers.length) {
      setWarningMessage('Lütfen tüm soruları cevaplayınız.')
      setWarningShow(!warningShow)
      return
    }
    const data = await createUserAnswer({
      title: surveyTitle,
      answeredQuestions: answers,
      description: message,
    })

    if (data.success) {
      setSuccessShow(true)
      setTimeout(() => {
        history.push('/')
        setSuccessShow(false)
      }, 1500)
    } else if (data.info.errorMessage) {
      setWarningMessage(data.info.errorMessage)
      setWarningShow(!warningShow)
    }
  }

  const onChange = (_, next) => {
    const mid = getMid()
    setMidItemIndex(mid + next.index)
  }

  const selectOption = ({ question, selected }) => {
    const filtered = answers.filter((answer) => answer.question !== question)
    setAnswers([...filtered, { question, selected }])
  }

  const arrowComponent = ({ type, onClick, isEdge }) => {
    const icon =
      type === consts.PREV
        ? 'fa fa-angle-double-left carouselIconLeft'
        : 'fa fa-angle-double-right carouselIconRight'
    return (
      <div className="carouselButtonWrapper">
        <div
          className="carouselButtonContainer"
          role="button"
          onClick={onClick}
        >
          <i className={icon} aria-hidden="true"></i>
        </div>
      </div>
    )
  }

  if (!showSurvey) {
    return (
      <div>
        <SweetAlert
          success
          title={<h1 className="alertSuccessTitle">Anket Başlatılıyor...</h1>}
          show={successShow}
          showConfirm={false}
        >
          <h1 className="alertContent">
            Anket sayfasına yönlendiriliyorsunuz...
          </h1>
        </SweetAlert>

        <SweetAlert
          error
          onConfirm={() => setWarningShow(!warningShow)}
          title={<h1 className="alertErrorTitle">Başarısız</h1>}
          show={warningShow}
          confirmBtnText={'Tamam'}
          confirmBtnBsStyle="danger"
        >
          <h1 className="alertContent">{warningMessage}</h1>
        </SweetAlert>
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo bg" style={{ flexDirection: 'column' }}>
            <img className="keshis" width="900px" src={keshis} />
          </div>
        </div>

        <div style={{ marginBottom: 20 }} className="container col-lg-4 ">
          <div className="registerContainer">
            <div className="inputContainer mt-3 pl-0">
              <input
                placeholder="Anket Başlığı Giriniz"
                className="input"
                type="text"
                value={surveyTitle}
                onChange={(e) => setSurveyTitle(e.target.value)}
                onKeyDown={(e) => (e.keyCode === 13 ? sumbit() : null)}
              />
            </div>

            <div onClick={() => sumbit()} className="buttonContainer mt-2 ">
              <h1>Başla</h1>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <SweetAlert
        success
        title={<h1 className="alertSuccessTitle">Başarılı</h1>}
        show={successShow}
        showConfirm={false}
      >
        <h1 className="alertContent">
          Anketiniz başarıyla bize ulaştı. Anasayfaya yönlendiriliyorsunuz...
        </h1>
      </SweetAlert>

      <SweetAlert
        error
        onConfirm={() => setWarningShow(!warningShow)}
        title={<h1 className="alertErrorTitle">Başarısız</h1>}
        show={warningShow}
        confirmBtnText={'Tamam'}
        confirmBtnBsStyle="danger"
      >
        <h1 className="alertContent">{warningMessage}</h1>
      </SweetAlert>
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo" style={{ flexDirection: 'column' }}>
          <img
            style={{ opacity: 0.4, zIndex: -1 }}
            className="keshis"
            width="900px"
            src={keshis}
          />
        </div>
      </div>
      <div className="container">
        {isMobile ? (
          <>
            {questions.lengt > 0 &&
              questions?.map((item, idx) => (
                <div className="col-lg-4 cardBg">
                  <Question key={item}>
                    {' '}
                    <div>
                      <h1 className="surveyQuestion">{item.text}</h1>

                      {item.options?.map((option, index) => {
                        return (
                          <>
                            <div
                              className="modern-radio-container"
                              onClick={() =>
                                selectOption({
                                  question: item._id,
                                  selected: option._id,
                                })
                              }
                            >
                              <div
                                className={`radio-outer-circle ${
                                  answers.findIndex(
                                    (answer) => answer.selected == option._id
                                  ) === -1 && 'unselected'
                                }`}
                              >
                                <div
                                  className={`radio-inner-circle ${
                                    answers.findIndex(
                                      (answer) => answer.selected == option._id
                                    ) === -1 && 'unselected-circle'
                                  }`}
                                />
                              </div>
                              <div className="helper-text">{option.text}</div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    <div className="cardNumberContainer">
                      <h1 className="questionNumber">{idx + 1}</h1>
                    </div>
                  </Question>
                </div>
              ))}

            <div
              style={{
                flex: 1,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <div className="inputMultilineContainerSurvey">
                <textarea
                  placeholder="Eklemek istediğiniz not varsa burada belirtebilirsiniz..."
                  rows="5"
                  className="inputMultilineSurvey"
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 25,
                  marginBottom: 25,
                }}
              >
                <h1 className="surveyQuestion">ANKETİ TAMAMLA</h1>
              </div>
            </div>
          </>
        ) : (
          <div style={{ marginBottom: 20 }} className="questionWrapper">
            <Carousel
              itemsToShow={itemsToShow}
              enableMouseSwipe={false}
              showEmptySlots
              breakPoints={breakPoints}
              renderArrow={arrowComponent}
              autoTabIndexVisibleItems={false}
              onNextStart={onChange}
              onPrevStart={onChange}
            >
              <div />
              {questions?.map((item, idx) => (
                  <Question
                    style={{
                      opacity: midItemIndex === idx ? 1 : 0.5,
                      transition:
                        midItemIndex === idx
                          ? 'transform 700ms ease'
                          : 'transform 300ms ease',
                      transform:
                        midItemIndex === idx ? 'scale(1)' : 'scale(.7)',
                    }}
                    key={item}
                  >
                    <div
                      className="surveyQuestion overflow-auto"
                      style={{ height: 290, flex: 1 }}
                    >
                      <h1 className="surveyQuestion">{item.text}</h1>

                      {item.options?.map((option, index) => {
                        return (
                          <>
                            <div
                              className="modern-radio-container"
                              onClick={() =>
                                selectOption({
                                  question: item._id,
                                  selected: option._id,
                                })
                              }
                            >
                              <div
                                className={`radio-outer-circle ${
                                  answers.findIndex(
                                    (answer) => answer.selected == option._id
                                  ) === -1 && 'unselected'
                                }`}
                              >
                                <div
                                  className={`radio-inner-circle ${
                                    answers.findIndex(
                                      (answer) => answer.selected == option._id
                                    ) === -1 && 'unselected-circle'
                                  }`}
                                />
                              </div>
                              <div className="helper-text">{option.text}</div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    <div className="cardNumberContainer">
                      <h1 className="questionNumber">{idx + 1}</h1>
                    </div>
                  </Question>
                ))}

              <div
                style={{
                  flex: 1,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <div className="inputMultilineContainerSurvey">
                  <textarea
                    placeholder="Eklemek istediğiniz not varsa burada belirtebilirsiniz..."
                    rows="5"
                    className="inputMultilineSurvey"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 25,
                    cursor: 'pointer',
                  }}
                  onClick={() => complateSurvey()}
                >
                  <h1 className="surveyQuestion">ANKETİ TAMAMLA</h1>
                </div>
              </div>
              <div />
            </Carousel>
          </div>
        )}
      </div>
    </div>
  )
}

export default Survey
